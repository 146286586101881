// extracted by mini-css-extract-plugin
export var icons = "Activities-module--icons--2BMed";
export var ActivityGroup = "Activities-module--ActivityGroup--2ow6v";
export var paperGrid = "Activities-module--paperGrid--3fAJu";
export var container = "Activities-module--container--1tPAv";
export var image = "Activities-module--image--Tr7sI";
export var itemtitle = "Activities-module--itemtitle--2P1Bw";
export var itemDescription = "Activities-module--itemDescription--pMc73";
export var itemcomp = "Activities-module--itemcomp--2KTXn";
export var itemDescriptionBlur = "Activities-module--itemDescriptionBlur--2uHn_";
export var overlay = "Activities-module--overlay--fBEk_";
export var text = "Activities-module--text--3yFyN";
export var goallbl1 = "Activities-module--goallbl1--1hI04";
export var goallbl2 = "Activities-module--goallbl2--3rVVn";
export var goallbl3 = "Activities-module--goallbl3--DqJ00";
export var goallbl = "Activities-module--goallbl--2o2JR";
export var desc = "Activities-module--desc--3CaOe";
export var containerforResearch = "Activities-module--containerforResearch--3qrio";
export var researchValidate = "Activities-module--researchValidate--30xQA";
export var Likes = "Activities-module--Likes--2bQez";
export var icontext = "Activities-module--icontext--26s5D";
export var iconimg = "Activities-module--iconimg--GRjmC";
export var label = "Activities-module--label--T4ff5";
export var media = "Activities-module--media--2s5wb";
export var growthArea = "Activities-module--growthArea--22KGA";
export var main = "Activities-module--main--1W-se";
export var statusIcon = "Activities-module--statusIcon--14c59";
export var box = "Activities-module--box--17lQ7";
export var Arrow = "Activities-module--Arrow--W5ATe";
export var Leaf = "Activities-module--Leaf--3cpAi";
export var Goal = "Activities-module--Goal--3jqse";
export var Description = "Activities-module--Description--3njHp";
export var Att1 = "Activities-module--Att1--35h9i";
export var Att = "Activities-module--Att--3fWum";
export var Title = "Activities-module--Title--3anLQ";
export var Like = "Activities-module--Like--3v85v";
export var CardInfo = "Activities-module--CardInfo--PiZ-d";
export var CardTitle = "Activities-module--CardTitle--1pELB";
export var CardDescription = "Activities-module--CardDescription--1uvPG";
export var CardAtt = "Activities-module--CardAtt--2yhuN";
export var CardDuration = "Activities-module--CardDuration--2kezJ";