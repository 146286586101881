import React from 'react';
import { graphql, Link } from 'gatsby';
import * as styles from '../styles/Activities.module.css';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Image } from 'cloudinary-react';
import { Container } from '@material-ui/core';
import TICK from '../images/icons/tick.png';
import ImagePlaceholder from '../images/Imageplaceholder.png';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';

export const query = graphql`
	query ActivitiesPageQuery {
		activities: allStrapiActivities(
			filter: {
				publish_to_app: { eq: true }
				weights: { in: [1, 2, 3] }
				activityOwnerId: { eq: null }
			}
		) {
			nodes {
				id
				title
				imageURL
				mediaURL
				method
				references
				researchValidated
				weights
				publish_to_app
				frequency
				duration
				description
				defaultFrequency
				benefit
				created_at
				createdAt
				attributes {
					code
					text
				}
				attribute {
					code
					text
				}
				activityId
				ReferencesGroup {
					URL
					id
				}
				GrowthAreas {
					Focus
					Spirit
					Strengths
					Relationships
					Purpose
					Hobbies
					SelfImage
					Health
				}
				AttributeGroup {
					IndoorOutdoor
					RepeatedOnetime
					IndividualGroup
				}
				Image {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
export default function Activities(props) {
	const { data } = props;

	//for getting Individual or group attribute
	function individualAttributes(activity) {
		let text = null;
		let flag = 0;
		//REP, IN, OUT, IND, GRP
		if (activity.attributes !== undefined) {
			activity?.attributes?.forEach((element) => {
				if (element.code === 'IND') {
					flag++;
					text = ' - Individual';
				} else if (element.code === 'GRP') {
					flag++;
					text = ' - Group';
				}
			});
		}
		if (flag === 0) {
			return '';
		}
		if (flag === 2) {
			return ' - Individual - Group';
		}
		return text;
	}

	//for getting indoor or outdoor attribute
	function inOutAttributes(activity) {
		let text = null;
		let flag = 0;
		//RE, IN, OUT, IND, GRP
		if (activity.attributes !== undefined) {
			activity?.attributes?.forEach((element) => {
				if (element.code === 'IN') {
					flag++;
					text = '  - Indoor';
				} else if (element.code === 'OUT') {
					flag++;
					text = ' - Outdoor';
				}
			});
		}
		if (flag === 0) {
			return '';
		}
		if (flag === 2) {
			return ' - Indoor - Outdoor';
		}
		return text;
	}

	function renderImage(
		activityId,
		imageURL,
		StrapiImage,
		itemTitle,
		researchValidated,
		itemDescription,
		itemLikes,
		growthAreas,
		itemLeaves,
		itemWeights
	) {
		let image;
		let statusIcon = null;
		try {
			if (StrapiImage) {
				image = (
					<div>
						<div className={styles.container}>
							<GatsbyImage
								image={StrapiImage.localFile.childImageSharp.gatsbyImageData}
								alt={itemTitle}
								height={250}
							/>
							<div className={styles.overlay}>
								<div className={styles.itemcomp}>
									<div className={styles.statusIcon}>{statusIcon}</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else if (imageURL) {
				let newImageURL = imageURL.split('/');
				image = (
					//wordpress card here
					<div>
						<div className={styles.container}>
							<Image
								cloudName='tektoniclabs'
								publicId={`${newImageURL[7]}/${newImageURL[8]}`}
								style={{ width: '100%', height: '250px' }}
							>
								{/* <Transformation crop='scale' style={{ width: '80%' }} /> */}
							</Image>

							<div className={styles.overlay}>
								<div className={styles.itemcomp}>
									<div className={styles.statusIcon}>{statusIcon}</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				image = (
					<img
						className={styles.image}
						src={ImagePlaceholder}
						alt={itemTitle}
						width='0.25'
						crop='scale'
					/>
				);
			}
		} catch (e) {
			image = (
				<img
					className={styles.image}
					src={ImagePlaceholder}
					alt={itemTitle}
					width='0.25'
					crop='scale'
				/>
			);
		}
		return image;
	}

	return (
		<Layout>
			<Container style={{ marginTop: '6em' }}>
				<Grid container spacing={2}>
					{/* Display all the activities */}
					<div className={styles.ActivityGroup}>
						{data.activities.nodes.map((activity, index) => {
							return (
								<Grid item xs={12} sm={4}>
									<div key={activity.activityId}>
										<div
											// onClick={this.props.onClick}
											className={styles.main}
										>
											<Link
												to={
													'/activity/' +
													activity.title
														.replace(/:/g, '')
														.replace(/\s+/g, '-')
														.toLowerCase()
												}
												style={{ textDecoration: 'none' }}
											>
												<Card
													style={{ borderRadius: '10px', boxShadow: 'none' }}
												>
													<div>
														{renderImage(
															activity.activityId,
															activity.imageURL,
															activity.Image,
															activity.title,
															activity.researchValidated,
															activity.description,
															activity.likes,
															activity.growthareas,
															activity.leaves,
															activity.goalWeights
														)}
													</div>
													{/* Card information below image starts here */}
													<Grid container className={styles.CardInfo}>
														<Grid item xs={9} sm={9}>
															{activity.duration !== null ? (
																<div className={styles.CardDuration}>
																	{activity.duration} min
																</div>
															) : null}
														</Grid>
														<Grid
															item
															xs={3}
															sm={3}
															style={{ textAlign: 'right' }}
														>
															{/* {this.displayLeaves(activity.leaves)} */}
														</Grid>
														<Grid item xs={12} sm={12}>
															<div className={styles.CardTitle}>
																{activity.title}
																{activity.researchValidated ? (
																	<div className={styles.iconimg}>
																		<img
																			src={TICK}
																			// src='../../assets/icons/tick.png'
																			// src={require('../assets/icons/tick.png').default}
																			alt='banner'
																			width='4%'
																		/>
																	</div>
																) : null}
															</div>
														</Grid>
														<Grid>
															<div className={styles.Goal}>
																{activity.GrowthAreas &&
																	Object.entries(activity.GrowthAreas)
																		.filter(([key, value]) => value !== 'None')
																		.map(([key, value]) => (
																			<div
																				key={key}
																				className={styles.goallbl3}
																			>
																				{key}
																			</div>
																		))}
															</div>
														</Grid>
														<Grid item xs={12} sm={12}>
															<div className={styles.CardDescription}>
																{activity.description.substring(0, 130) +
																	'...Read More'}
															</div>
														</Grid>
														<Grid item xs={12} sm={12}>
															<div className={styles.CardAtt}>
																{activity.frequency}
																{individualAttributes(activity)}{' '}
																{inOutAttributes(activity)}
															</div>
														</Grid>
													</Grid>
												</Card>
											</Link>
										</div>
									</div>
								</Grid>
							);
						})}
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}
